<template>
  <div id="employeeApply">
    <div class="left">
      <div class="backBox">
        <btn-back></btn-back>
      </div>
      <div class="loginTextBox" v-if="loginText">
        <div class="loginTextContent">
          <h1>Welcome to join us</h1>

          <div class="userCenter">
            <h3>
              第一步，請先選擇您的位置
              這將決定您工作的位置。
              如果您不知道您公司的編號
              請聯繫相關工作人員獲取
            </h3>
          </div>
          <div class="downloadBox">
            <h2 class="downloadText">Download APP:</h2>
            <svg-icon name="google" size=90></svg-icon>
            <svg-icon class="isoBox" name="iso" size=90></svg-icon>
          </div>
        </div>
      </div>

      <div class="loginTextBox" v-if="signUpText">
        <div class="loginTextContent">
          <h1>Welcome To Join Us</h1>
          <div class="userCenter">
            <h3>Encountered a problem?</h3>
          </div>
          <div class="downloadBox">
            <h2 class="downloadText">Download APP:</h2>
            <svg-icon name="google" size=90></svg-icon>
            <svg-icon class="isoBox" name="iso" size=90></svg-icon>
          </div>
        </div>
      </div>

      <div id="wave">
        <div class="wavebackground">
          <div class="waveBack" id="waveBack" :class="{ waveMove:isWave,loginWaveMove:loginWave }" :style="{ background:waveBoxColor }"></div>
          <div class="wave">
            <div class="wave3">
              <svg width="344" height="960" viewBox="0 0 344 960" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M83.0754 1661L111.249 1597C138.7 1533 194.324 1405 200.826 1277C208.05 1149 166.151 1021 194.324 893C221.775 765 319.298 637 339.525 509C360.475 381 304.85 253 235.5 125C166.151 -2.99999 83.0753 -131 41.8989 -195L-1.09611e-05 -259V-259L-8.56122e-06 -195C-6.16134e-06 -131 -1.36159e-06 -2.99998 3.43816e-06 125C8.23791e-06 253 1.30377e-05 381 1.78374e-05 509C2.26372e-05 637 2.74369e-05 765 3.22367e-05 893C3.70364e-05 1021 4.18362e-05 1149 4.66359e-05 1277C5.14357e-05 1405 5.62354e-05 1533 5.86353e-05 1597L6.10352e-05 1661L83.0754 1661Z" 
                  :fill="wave3color"/>
              </svg>
            </div>
            <div class="wave2">
              <svg width="255" height="960" viewBox="0 0 255 960" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M82.8806 -451L108.87 -411.833C135.316 -372.667 187.751 -294.333 174.528 -216C161.761 -137.667 82.8806 -59.3333 47.7719 19C12.6632 97.3333 21.3264 175.667 43.2123 254C65.0983 332.333 100.207 410.667 135.316 489C170.424 567.333 205.533 645.667 227.419 724C249.305 802.333 257.968 880.667 244.745 959C231.522 1037.33 196.414 1115.67 165.865 1194C135.316 1272.33 108.87 1350.67 96.1033 1389.83L82.8805 1429L4.00001 1429L4.00001 1389.83C4.00001 1350.67 4.00001 1272.33 4.00001 1194C4.00002 1115.67 4.00002 1037.33 4.00002 959C4.00003 880.667 4.00003 802.333 4.00003 724C4.00004 645.667 4.00004 567.333 4.00004 489C4.00004 410.667 4.00005 332.333 4.00005 254C4.00005 175.667 4.00006 97.3333 4.00006 19C4.00006 -59.3333 4.00006 -137.667 4.00007 -216C4.00007 -294.333 4.00007 -372.667 4.00007 -411.833L4.00008 -451L82.8806 -451Z" 
                  :fill="wave2color"/>
                <defs>
                <filter id="filter0_d" x="0" y="-451" width="255" height="1888" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                </defs>
              </svg>
            </div>
            <div class="wave1">
              <svg width="326" height="1256" viewBox="0 0 326 1256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M263.499 12C263.499 12 263.499 31 279.728 66.5C295.956 102 309.226 186 297.585 293C285.945 400 204.463 552 163.47 623C122.476 694 84.8507 828.5 95.1463 930.5C104.927 1032.5 148.257 1142 178.115 1193L207.457 1244L7.18273e-06 1244L9.41201e-06 1193C1.16413e-05 1142 1.60998e-05 1040 2.05584e-05 938C2.5017e-05 836 2.94755e-05 734 3.39341e-05 632C3.83927e-05 530 4.28512e-05 428 4.73098e-05 326C5.17683e-05 224 5.62269e-05 122 5.84562e-05 71L6.10352e-05 12L263.499 12Z" 
                  :fill="wave1color"/>
                  </g>
                  <defs>
                  <filter id="filter0_d" x="0" y="0" width="326" height="1256" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="12"/>
                  <feGaussianBlur stdDeviation="6"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="loginform">
        <progress-bar>
          <progress-item v-for="item in progressList" :key="item.id">
            <template v-slot:step>{{item.step}}</template>
            <template v-slot:text>{{item.text}}</template>
          </progress-item>
        </progress-bar>
        <empolyee-form-one v-if="page==1" @isFormOneOk="isFormOneOk" @form="pageOneForm" :chickShow="pageChickShow"></empolyee-form-one>
        <empolyee-form-two v-if="page==2" @isFormTwoOk="isFormTwoOk" @form="pageTwoForm" :chickShow="pageChickShow"></empolyee-form-two>
        <empolyee-form-three v-if="page==3" @isFormThreeOk="isFormThreeOk" @form="pageThreeForm" :chickShow="pageChickShow"></empolyee-form-three>
        <empolyee-form-four v-if="page==4" @isFormFourOk="isFormFourOk" :chickShow="pageChickShow"></empolyee-form-four>
        <empolyee-form-five v-if="page==50"></empolyee-form-five>
        <div class="controllBox">
          <btn-submit class="btnBack" color="red" @click="goBack()">{{btnBack}}</btn-submit>
          <btn-submit class="btnNext" @click="goNext()">{{btnTitle}}</btn-submit>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import BtnBack from '@/components/button/BtnBack.vue'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import EmpolyeeFormOne from './formEmployee/EmpolyeeFormOne.vue'
import EmpolyeeFormTwo from './formEmployee/EmpolyeeFormTwo.vue'
import EmpolyeeFormThree from './formEmployee/EmpolyeeFormThree.vue'
import EmpolyeeFormFour from './formEmployee/EmpolyeeFormFour.vue'
import EmpolyeeFormFive from './formEmployee/EmpolyeeFormFive.vue'
import ProgressBar from '@/components/progressBar/ProgressBar.vue'
import ProgressItem from '@/components/progressBar/ProgressItem.vue'

import {saveEmployee} from "@/axios/api/employee"

export default {
  name:'EmployeeApply',
  components: {
    BtnBack,
    BtnSubmit,
    SvgIcon,
    EmpolyeeFormOne,
    EmpolyeeFormTwo,
    EmpolyeeFormThree,
    EmpolyeeFormFour,
    EmpolyeeFormFive,
    ProgressBar,
    ProgressItem,
  },
  data() {
    return {
      btnBack:'Back',
      btnTitle:'Next',
      page:1,
      wave3color:"#0F87CB",
      wave2color:"#0C9AEA",
      wave1color:"#0099FF",
      waveBoxColor:"#0099FF",
      isWave:false,
      loginWave:false,
      btnColor:"red",
      loginText:true,
      signUpText:false,
      postForm:{
        address: "",
        birth: "",
        city: "",
        company: "",
        companyHistory: "",
        contactName: "",
        contactPhone: 0,
        department: "",
        diplomaPhoto: "",
        district: "",
        educationDegree: "",
        fristName: "",
        gender: 0,
        id: "",
        idCard: "",
        jobContent: "",
        lastName: "",
        major: "",
        marital: "",
        midName: "",
        minor: "",
        nationality: "",
        phone: "",
        photo: "",
        position: "",
        positionHistory: "",
        salaryHistory: "",
        schoolName: "",
        state: "",
        workEnd: "",
        workStart: "",
        zipCode: ""
      },
      nextBoolen:false,
      pageChickShow:false,
      postFormBoolen:false,
      progressList:[
        {
          step:'1',
          text:'Choose Your Job'
        },
        {
          step:'2',
          text:'Personal information'
        },
        {
          step:'3',
          text:'Personal information'
        },
        {
          step:'4',
          text:'Bank Infomation'
        },
      ]
    }
  },
  methods:{
    goNext(){
      if(this.nextBoolen == true){
        this.page++
        this.nextBoolen = false
        this.pageChickShow = false
      }
      else{
        this.pageChickShow = true
      }
      if(this.page==5){
        this.btnTitle="Finish"
      }else{
        this.btnTitle="Next"
      }

      if(this.postFormBoolen == true){
        saveEmployee(this.postForm).then(res => {
          if(res.code === 20000){
            this.page = 50
            console.log(res)
          }
          else{
            alert("cccc")
          }
        })
      }

      if(this.page > 4){
        this.page = 5
      }
    },
    goBack(){
      this.page--
      if(this.page<=0){
        this.page=1
        window.location.href = '/usercenter'
      }
      this.postFormBoolen = false
    },

    isFormOneOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormTwoOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormThreeOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormFourOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
        this.postFormBoolen = true
      }
      else{
        this.nextBoolen = false
        this.postFormBoolen = false
      }
    },
    pageOneForm(form){
      this.postForm.company = form.companyNumber
      this.postForm.department = form.departmentTitle
      this.postForm.position = form.positionTitle
    },
    pageTwoForm(form){
      this.postForm.fristName = form.firstName
      this.postForm.midName = form.midName
      this.postForm.lastName = form.lastName
      this.postForm.birth = form.dateBirth
      this.postForm.marital = form.maritalTitle
      this.postForm.phone = form.PphoneNumberOne
      this.postForm.address = form.Address
      this.postForm.zipCode = form.ZIP
      this.postForm.city = form.City
      this.postForm.district = form.District
      this.postForm.contactPhone = form.EmergencyPhoneNumber
      this.postForm.contactName = form.EmergencyFirstName + "_" + form.EmergencyMidName + "_" + form.EmergencyLastName

      if(form.sexTitle == "Man"){
        this.postForm.gender = 1
      }
      else if(form.sexTitle == "Woman"){
        this.postForm.gender = 0
      }
    },
    pageThreeForm(form){
      this.postForm.schoolName = form.yourSchool
      this.postForm.major = form.yourMajorMain
      this.postForm.minor = form.yourMajor
      this.postForm.educationDegree = form.doctoralDegree
      this.postForm.companyHistory = form.companyName
      this.postForm.positionHistory = form.yourPosition
      this.postForm.salaryHistory = form.salary
      this.postForm.workStart = form.jobDate[0]
      this.postForm.workEnd = form.jobDate[1]
      this.postForm.jobContent = form.jobContentLine
      this.postForm.id = this.$store.state.userBaseInfo.id
    }
  },
}
</script>

<style scoped>
#employeeApply{
  position: relative;
  width: 100vw;
  height: 100%;
  background: #f9f9f9;
  display: flex;
}
.controllBox{
  display: flex;
}
.left{
  position: relative;
  left: 0;
  width: 20vw;
  height: 100%;
}
.right{
  position: relative;
  right: 0;
  height: 100vh;
  width: 80vw;
  padding-top: 20px;
}
.logoBox{
  display: flex;
  justify-content: center;
  background: #0099FF;
  width: 100px;
  height: 100px;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 20px auto;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
}
.logo{
  height: 60px;
}
.logotext{
  display: flex;
  font-size: 40px;
  justify-content: center;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.line1{
  margin-bottom: 20px;
}
.loginform{
  height: 500px;
  width: 84%;
  margin-left: 10%;
  margin-right: 6%;
}
.loginTextContent{
  position: absolute;
  z-index: 9;
  color: #fff;
  top: 10%;
  left: 6%;
  text-align: start;
  width: 100%;
  transition: .6s;
}
.backBox{
  position: absolute;
  z-index: 9;
}

h1,.line1Text{
  font-size: 34px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: start;
}
h2,h3{
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.point{
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 16px;
}
.btn{
  margin-top: -30px;
  margin-left: 30px;
}
.userCenter{
  position: relative;
  width: 90%;
  margin-top: 40px;
  text-align: start;
  display: flex;
  height: 340px;
}
.goUserCenter{
  color: blue;
  cursor: pointer;
  margin-left: 20px;
  transition: .4s;
}
.goUserCenter:hover{
  color: rgba(3, 3, 141, 0.795);
}
.signUpLine{
  display: flex;
  justify-content: center;
}
.signUpText{
  color: blue;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 900;
  width: 100px;
  height: 20px;
}
.isoBox{
  margin-left: 10px;
}
.downloadText{
  position: absolute;
  font-size: 20px;
}
.downloadBox{
  position: relative;
  margin-top: 100px;
}
#wave{
  display: flex;
  height: 100vh;
}
.wavebackground{
  display: flex;
  height: 100%;
}
.waveBack{
  background: #0099FF;
  height: 100vh;
  width: 121px;
  z-index: 2;
}
.wave{
  position: relative;
  height: 100%;
}
.wave1{
  position: absolute;
  left: 0;
  z-index: 3;
}
.wave1 svg{
  height: 100%;
  margin-top: -60px;
  z-index: 9;
}
.wave2{
  position: absolute;
  left: 0px;
  z-index: 2;
}
.wave3{
  position: absolute;
  left: -60px;
  z-index: 1;
}
.waveMove{
  animation: toleft 1s ease-in-out;
}
.loginWaveMove{
  animation: toleft 1s ease-in-out;
}
svg{
  transition: .6s;
}

@keyframes toleft {
  0%{
    width: 620px;
  }
  50%{
    width: 100vw;
  }
  100%{
    width: 621px;
  }
}
.btnNext{
  margin-left: 80%;
}
</style>
