import request from "../request"

export function getEmployeeList() {
  return request({
    url: '/admin/employee'
  })
}

export function saveEmployee(employeeForm) {
  return request({
    url: '/admin/crm/employee/save',
    method: 'post',
    data: employeeForm
  })
}