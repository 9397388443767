<template>
  <div id="empolyeeformone">
    <div class="workType">
      <Validator type="number" :value="form.companyNumber" @isCheckOkValue="companyNumberCheck" :show="chickShow">
        <input-text placeholder="Please Enter Company Number"
                    v-model="form.companyNumber" oninput="value=value.replace(/[^\d.]/g,'')">Company Number</input-text>
      </Validator>
      <div class="titleText">
        <h2>The Position you are applying for:</h2>
      </div>
      <div class="jobBox">
        <div class="department">
          <Validator type="drowDownMeun" defaultName="Department" :titleName="form.departmentTitle" @isCheckOkValue="DepartmentCheck" :show="chickShow">
            <select-menu :title="form.departmentTitle">
              <option-menu v-for="item in deparmentList" :key="item.id" @click="selectDepartmentItem(item)">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>
        <div class="position">
          <Validator type="drowDownMeun" defaultName="Position" :titleName="form.positionTitle" @isCheckOkValue="PositionCheck" :show="chickShow">
            <select-menu :title="form.positionTitle">
              <option-menu v-for="item in postionList" :key="item.id" @click="selectPositionItem(item)">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu.vue'
import OptionMenu from '@/components/selectMenu/OptionMenu.vue'
import InputText from '@/components/XuComponents/form/InputText'
import Validator from '@/components/validator/Validator'

import { getDepartmentList,getPostionList } from '@/axios/api/department'

export default {
  name:'EmpolyeeFormOne',
  components: {
    SelectMenu,
    OptionMenu,
    InputText,
    Validator
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      getMonitor:0,
      deparmentList:[],
      postionList:[],
      form:{
        companyNumber:"",
        departmentTitle:'Department',
        positionTitle:'Position',
      },
      isCheckAllOkForm:{
        companyNumberCheck:false,
        DepartmentCheck:false,
        PositionCheck:false,
      },
      isFormOneOk:false,
    }
  },
  created(){
    this.getDepartmentList()
    this.getPostionList()
  },
  methods:{
    selectDepartmentItem(item){
      this.form.departmentTitle = item.name
    },
    selectPositionItem(item){
      this.form.positionTitle = item.name
    },
    getDepartmentList(){
      getDepartmentList().then(res=>{
        this.deparmentList = res.data.items
      })
    },
    getPostionList(){
      getPostionList().then(res=>{
        this.postionList = res.data.items
      })
    },
    companyNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.companyNumberCheck = CheckBoolen
      this.changPage()
    },
    DepartmentCheck(CheckBoolen){
      this.isCheckAllOkForm.DepartmentCheck = CheckBoolen
      this.changPage()
    },
    PositionCheck(CheckBoolen){
      this.isCheckAllOkForm.PositionCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.companyNumberCheck == true 
      && this.isCheckAllOkForm.DepartmentCheck == true 
      && this.isCheckAllOkForm.PositionCheck == true){
        this.isFormOneOk = true
        this.$emit('isFormOneOk', this.isFormOneOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOneOk = false
        this.$emit('isFormOneOk', this.isFormOneOk)
      }
    },

  },
}
</script>

<style scoped>
#empolyeeformone{
  position: relative;
  width: 100%;
  height: 80%;
}
.jobBox{
  display: flex;
  margin-top: 20px;
}
.position{
  margin-left: 40px;
}
.titleText{
  margin-top: 60px;
}
.btnNext{
  margin-top: 140px;
  margin-left: 80%;
}
</style>
