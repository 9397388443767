<template>
  <div id="empolyeeformthree">
    <div class="content1">
      <update-id-card>Authentication:</update-id-card>
    </div>
    <div class="content">
      <drop-menu title="Educational History">
        <div class="menuItem">
          <div class="titleBox"><h3>School Name:</h3></div>
          <div class="contentBox">
            <Validator type="text" :value="form.yourSchool" :show="chickShow" @isCheckOkValue="yourSchoolCheck">
              <input-text placeholder="Your School" v-model="form.yourSchool"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Major(main):</h3></div>
          <div class="contentBox">
            <Validator type="text" :value="form.yourMajorMain" :show="chickShow" @isCheckOkValue="yourMajorMainCheck">
              <input-text placeholder="Your Major" v-model="form.yourMajorMain"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Minor:</h3></div>
          <div class="contentBox">
            <Validator type="text" :value="form.yourMajor" :show="chickShow" @isCheckOkValue="yourMajorCheck">
              <input-text placeholder="Your Major" v-model="form.yourMajor"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Doctoral degree:</h3></div>
          <div class="harfItem">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.doctoralDegree" :show="chickShow" @isCheckOkValue="doctoralDegreeCheck">
              <select-menu :title="form.doctoralDegree">
                <option-menu v-for="item in doctoralDegreeList" :key="item.id" @click="doctoralDegreeItem(item)">{{item.name}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Ddiploma:</h3></div>
          <div class="harfItem"><update-id-card></update-id-card></div>
        </div>
      </drop-menu>

      <drop-menu title="Educational History2" >
        <div class="menuItem">
          <div class="titleBox"><h3>School Name:</h3></div>
          <div class="contentBox"><input-text placeholder="Your School"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Major:</h3></div>
          <div class="contentBox"><input-text placeholder="Your Major"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Minor:</h3></div>
          <div class="contentBox"><input-text placeholder="Your Major"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Doctoral degree:</h3></div>
          <div class="harfItem"><select-menu></select-menu></div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Ddiploma:</h3></div>
          <div class="harfItem"><update-id-card></update-id-card></div>
        </div>
      </drop-menu>
    </div>
    <div class="content">
      <drop-menu title="Work Experience">
        <div class="menuItem">
          <div class="titleBox"><h4>Company Name:</h4></div>
          <div class="contentBox">
            <Validator type="text" :value="form.companyName" :show="chickShow" @isCheckOkValue="companyNameCheck">
              <input-text placeholder="Company Name" v-model="form.companyName"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Position:</h3></div>
          <div class="contentBox">
            <Validator :value="form.yourPosition" :show="chickShow" @isCheckOkValue="yourPositionCheck">
              <input-text placeholder="Your Position" v-model="form.yourPosition"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Salary:</h3></div>
          <div class="contentBox">
            <Validator type="number" :value="form.salary" :show="chickShow" @isCheckOkValue="salaryCheck">
              <input-text placeholder="Your Salary" v-model="form.salary" oninput="value=value.replace(/[^\d.]/g,'')"></input-text>
            </Validator>
          </div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Start - End:</h3></div>
          <div>
            <Validator type="drowDownMeun" defaultName="" :titleName="form.jobDate" :show="chickShow" @isCheckOkValue="jobDateCheck">
              <el-date-picker
                v-model="form.jobDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="YYYY-MM-DD HH:mm:ss">
              </el-date-picker>
            </Validator>
          </div>
        </div>

        <div class="noItem">
          <div class="title"><h2>Job content:</h2></div>
          <input-text placeholder="Your Job Content" v-model="jobContentLineOne"></input-text>
          <Validator :value="form.jobContentLine" :show="chickShow" @isCheckOkValue="jobContentLineCheck">
            <input-text placeholder="" v-model="jobContentLineTwo"></input-text>
          </Validator>
        </div>
      </drop-menu>

      <drop-menu title="Work Experience2">
        <div class="menuItem">
          <div class="titleBox"><h4>Company Name:</h4></div>
          <div class="contentBox"><input-text placeholder="Company Name"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Position:</h3></div>
          <div class="contentBox"><input-text placeholder="Your Position"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="titleBox"><h3>Salary:</h3></div>
          <div class="contentBox"><input-text placeholder="Your Salary"></input-text></div>
        </div>

        <div class="menuItem">
          <div class="harfItem1"><h3>Start - End:</h3></div>

          <el-date-picker
            v-model="dateValue"
            type="daterange"
            range-separator="To"
            start-placeholder="Start"
            end-placeholder="End">
          </el-date-picker>
        </div>

        <div class="noItem">
          <div class="title"><h2>Job content:</h2></div>
          <input-text placeholder="Your Job Content"></input-text>
          <input-text placeholder=""></input-text>
        </div>
      </drop-menu>
    </div>
  </div>
</template>

<script>
import UpdateIdCard from '@/components/update/UpdateIdCard.vue'
import DropMenu from '@/components/menu/DropMenu.vue'
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '../../../../components/selectMenu/SelectMenu.vue'
import Validator from '@/components/validator/Validator'
import OptionMenu from '@/components/selectMenu/OptionMenu'

export default {
  name:'EmpolyeeFormThree',
  components: {
    UpdateIdCard,
    DropMenu,
    InputText,
    SelectMenu,
    Validator,
    OptionMenu,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      dateValue: '',
      
      jobContentLineOne:"",
      jobContentLineTwo:"",
      
      doctoralDegreeList:[
        {name:"High school"},
        {name:"Bachelor's Degree"},
        {name:"Master's Degree "},
        {name:"Doctoral Degree"},
      ],

      form:{
        yourSchool:"",
        yourMajorMain:"",
        yourMajor:"",
        doctoralDegree:"Select",
        companyName:"",
        yourPosition:"",
        salary:"",
        jobDate:"",
        jobContentLine:"",
      },
      isCheckAllOkForm:{
        yourSchoolCheck:false,
        yourMajorMainCheck:false,
        yourMajorCheck:false,
        doctoralDegreeCheck:false,
        companyNameCheck:false,
        yourPositionCheck:false,
        salaryCheck:false,
        jobDateCheck:false,
        jobContentLineCheck:false,
      },
      isFormThreeOk:false,
    }
  },
  methods:{
    doctoralDegreeItem(item){
      this.form.doctoralDegree = item.name
    },

    yourSchoolCheck(CheckBoolen){
      this.isCheckAllOkForm.yourSchoolCheck = CheckBoolen
      this.changPage()
    },
    yourMajorMainCheck(CheckBoolen){
      this.isCheckAllOkForm.yourMajorMainCheck = CheckBoolen
      this.changPage()
    },
    yourMajorCheck(CheckBoolen){
      this.isCheckAllOkForm.yourMajorCheck = CheckBoolen
      this.changPage()
    },
    doctoralDegreeCheck(CheckBoolen){
      this.isCheckAllOkForm.doctoralDegreeCheck = CheckBoolen
      this.changPage()
    },
    companyNameCheck(CheckBoolen){
      this.isCheckAllOkForm.companyNameCheck = CheckBoolen
      this.changPage()
    },
    yourPositionCheck(CheckBoolen){
      this.isCheckAllOkForm.yourPositionCheck = CheckBoolen
      this.changPage()
    },
    salaryCheck(CheckBoolen){
      this.isCheckAllOkForm.salaryCheck = CheckBoolen
      this.changPage()
    },
    jobDateCheck(CheckBoolen){
      this.isCheckAllOkForm.jobDateCheck = CheckBoolen
      this.changPage()
    },
    jobContentLineCheck(CheckBoolen){
      this.isCheckAllOkForm.jobContentLineCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.yourSchoolCheck == true 
      && this.isCheckAllOkForm.yourMajorMainCheck == true 
      && this.isCheckAllOkForm.yourMajorCheck == true 
      && this.isCheckAllOkForm.doctoralDegreeCheck == true 
      && this.isCheckAllOkForm.companyNameCheck == true 
      && this.isCheckAllOkForm.yourPositionCheck == true 
      && this.isCheckAllOkForm.salaryCheck == true 
      && this.isCheckAllOkForm.jobDateCheck == true 
      && this.isCheckAllOkForm.jobContentLineCheck == true ){
        this.isFormThreeOk = true
        this.$emit('isFormThreeOk', this.isFormThreeOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormThreeOk = false
        this.$emit('isFormThreeOk', this.isFormThreeOk)
      }
    }
  },
  watch:{
    "jobContentLineOne"(){
      this.form.jobContentLine = this.jobContentLineOne + this.jobContentLineTwo
      // console.log(this.jobContentLine)
    },
    "jobContentLineTwo"(){
      this.form.jobContentLine = this.jobContentLineOne + this.jobContentLineTwo
      // console.log(this.jobContentLine)
    },
    "isFormThreeOk"(){
      console.log(this.isFormThreeOk)
    }
  }
}
</script>

<style scoped>
#empolyeeformthree{
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
.content1{
  width: 20%;
}
.content{
  width: 40%;
  margin-right: 1%;
}
.menuItem{
  width: 100%;
  display: flex;
  align-items: center;
}
.titleBox{
  width: 32%;
  margin-right: 20px;
}
.contentBox{
  width: 70%;
}
.harfItem1{
  width: 40%;
  margin-right: 40px;
}
.noItem{
  margin-top: 20px;
}

</style>
