<template>
  <div id="progressBar">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name:'ProgressBar',
  components: {
    
  },
  data() {
    return {

    }
  },
  methods:{

  },
}
</script>

<style scoped>
#progressBar{
  width: 100%;
  height: 120px;
  display: flex;
  margin-bottom: 40px;
}

</style>
