<template>
  <div id="empolyeeformfour">
    <div class="titleBox">
      <h1>It is almost finished!!!</h1>
      <h1>Please read the following terms carefully.</h1>
    </div>

    <div class="termBox">
      
    </div>

    <div class="signedContractBox">
      <div class="signItem">
        <input class="checkbox" type="checkbox" v-model="checkboxOne">
        <div class="textbox">
          <h2>我確保我提供的信息為真實信息，並願意為此承擔法律責任</h2>
        </div>
      </div>
      <div class="signItem">
        <input class="checkbox" type="checkbox" v-model="checkboxTwo">
        <div class="textbox">
          <h2>我確保我提供的信息為真實信息，並願意為此承擔法律責任</h2>
        </div>
      </div>
      <div class="signItem">
        <input class="checkbox" type="checkbox" v-model="checkboxThree">
        <div class="textbox">
          <h2>我確保我提供的信息為真實信息，並願意為此承擔法律責任</h2>
        </div>
      </div>
      <div id="worringBox">
        <div id="worring" v-show="chickShow">
          <span>{{worringText}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'EmpolyeeFormFour',
  components: {
  },
  data() {
    return {
      checkboxOne:"",
      checkboxTwo:"",
      checkboxThree:"",

      worringText:"",

      isFormFourOk:false
    }
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    isAllOk(){
      if(this.checkboxOne == true 
      && this.checkboxTwo == true 
      && this.checkboxThree == true ){
        this.isFormFourOk = true
        this.worringText = ""
        this.$emit('isFormFourOk', this.isFormFourOk)
      }
      else{
        this.isFormFourOk = false
        this.worringText = "*必須都勾選"
        this.$emit('isFormFourOk', this.isFormFourOk)
      }
    }
  },
  mounted() {
    this.isAllOk()
  },
  watch:{
    "checkboxOne"(){
      this.isAllOk()
    },
    "checkboxTwo"(){
      this.isAllOk()
    },
    "checkboxThree"(){
      this.isAllOk()
    },
  }
}
</script>

<style scoped>
@import url(https://cdn.bootcss.com/font-awesome/5.13.0/css/all.css);
#empolyeeformfour{
  max-height: 100%;
}
.titleBox{
  text-align: center;
}
.termBox{
  height: 200px;
  width: 80%;
  margin-left: 10%;
  background: #C4C4C4;
  border-radius: 20px;
  border: 2px solid #000;
}
.signedContractBox{
  width: 80%;
  margin-left: 10%;
  padding-top: 10px;
}
.signItem{
  width: 80%;
  margin-left: 10%;
  height: 40px;
  display: flex;
  align-items: center;
}
.checkbox{
  height: 24px;
  width: 24px;
}
.textbox{
  margin-left: 20px;
  text-align: center;
}
#worringBox{
  width: 100%;
  height: 20px;
  display: flex;
}
#worring{
  margin: 0 auto;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
</style>
