<template>
  <div class="dropmenu" @click="btnActive" :class="{dropMenuActive:isActive}">
    <div class="text">{{title}}</div>
    <div class="icon" :class="{active:isActive}"><svg-icon name="down"></svg-icon></div>
  </div>
  <div class="menu" :class="{menuActive:!isActive}">
    <slot></slot>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon/SvgIcon.vue'
export default {
  name:'DropMenu',
  components: {
    SvgIcon
  },
  data() {
    return {
      isActive: true
    }
  },
  props:{
    title: String
  },
  methods:{
    btnActive(){
      this.isActive=!this.isActive
    }
  },
}
</script>

<style scoped>
.dropmenu{
  width: 90%;
  height: 40px;
  background: #EEEFF4;
  margin-left: 5%;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.text{
  text-align: center;
  width: 90%;
}
.active{
  transform: rotate(180deg);
}
.icon{
  transition: .4s;
}
.menu{
  height: 0;
  overflow: hidden;
  width: 90%;
  margin-left: 5%;
  transition: .4s;
  max-height: 500px;
}
.menuActive{
  height: 400px;
}
.dropMenuActive{
  background: #3C7AD9;
  width: 90%;
  height: 40px;
  color: #EEEFF4;
}
</style>
