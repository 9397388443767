import request from "../request"

export function addDepartment(departmentForm) {
  return request({
    url: '/admin/crm/department/save-department-info',
    method: 'post',
    data: departmentForm
  })
}

export function deleteDepartment(id) {
  return request({
    url: '/admin/crm/department/delete/'+ id,
    method: 'delete',
  })
}

export function upadtaDepartment() {
  return request({
    url: '/admin/crm/department/update'
  })
}

export function getDepartmentList() {
  return request({
    url: '/admin/crm/department/list',
    method: 'get',
  })
}

export function getPostionList() {
  return request({
    url: '/admin/crm/position/list',
    method: 'get',
  })
}