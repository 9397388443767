<template>
  <div id="empolyeeformfive">
    <div class="SomethingBox" style="height: 64px;margin-bottom: 20px;">
      <div class="spanitem">
        <span>省核中，請耐心等待。</span>
      </div>
    </div>
    <div class="SomethingBox" style="height: 56px;margin-bottom: 135px;">
      <div class="spanitem2">
        <span>我們會盡快審核您的資料，歡迎隨時登錄賬號查詢進度。▶（這可能會花費2-5個工作日）</span>
      </div>
    </div>
    <div class="SomethingBox" style="height: 28px;margin-bottom: 0px;">
      <div class="spanitem2" style="">
        <div style="margin-right: 42px;">
          <span>{{sec}}秒後跳回首頁</span>
        </div>
        <div id="secondSpan">
          <a href="#" @click="goCenter">
            <span >直接跳轉</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'EmpolyeeFormFive',
  components: {

  },
  data() {
    return {
      sec:10
    }
  },
  methods:{
    sendCode(){
        const countDown = setInterval(()=>{
          this.sec-- ;
          if(this.sec == 0){
            clearInterval(countDown);
            window.location.href = '/usercenter'
          }
        },1000);
    },
    goCenter(){
      window.location.href = '/usercenter'
    }
  },
  mounted() {
    this.sendCode()
  },
}
</script>

<style scoped>
.SomethingBox{
  width: 100%;
  /* background: wheat; */
  display: flex;
}
.spanitem{
  /* border: #FF004D 1px solid; */
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
}
.spanitem2{
  /* border: #FF004D 1px solid; */
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
}
#secondSpan{
  color: #5E69C7;
}
a{
  text-decoration:none;
}
a:visited{
  color: #705CBD;
}
</style>
