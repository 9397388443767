<template>
  <div id="progressitem">
    <div class="item">
      <div class="itemIcon" :class="{isActive: isOk}">
        <div class="stepText" v-if="!isOk"><h1><slot name="step"></slot></h1></div>
        <svg-icon name="right" size="80" v-if="isOk"></svg-icon>
      </div>
      <div class="text"><h5><slot name="text"></slot></h5></div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon/SvgIcon.vue'
export default {
  name:'ProgressItem',
  components: {
    SvgIcon
  },
  props:{
    isOk:{
      type: Boolean,
      default: false
    },

  },
  methods:{},
}
</script>

<style scoped>
#progressitem{
  width: 360px;
  text-align: center;
}
.itemIcon{
  width: 60px;
  height: 60px;
  border: 2px solid #f1f1f1;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  background: #EEEFF4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  cursor: pointer;
  transition: .4s;
}
.item{
  margin-right: 80px;
}
.stepText{
  width: 58px;
  height: 58px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  background: #FF1057;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #EEEFF4;
  transition: .4s;
}
.text{
  margin-top: 20px;
  color: #8b8b8b;
}
.isActive{
  width: 80px;
  height: 80px;
}

</style>
