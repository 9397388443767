<template>
  <div id="btnbackMain">
    <div class="btnBackclick" @click="goBack()">
      <svg-icon name="left" size= 30 color="#0099FF"/>
    </div>
    <span>Back</span>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
export default {
  name:'BtnBack',
  components: {
    SvgIcon
  },
  data() {
    return {

    }
  },
  methods:{
    goBack(){
      this.$router.back()
    }
  },
}
</script>

<style scoped>
#btnbackMain{
  position: relative;
  top: 20px;
  z-index: 9;
  color: #fff;
  left: 40px;
  display: flex;
}
.btnBackclick{
  width: 38px;
  height: 38px;
  left: 40px;
  top: 40px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
}
.btnBackclick:hover{
  cursor: pointer;
  background: rgb(223, 223, 223);
  box-shadow: inset 5px 4px 6px #cbced1,
              inset -5px -4px 6px #fff;
              
}
span{
  font-size: 26px;
  margin-left: 20px;
}
</style>
