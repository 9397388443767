<template>
  <div id="empolyeeformtwo">
    <div class="left">
      <!-- <Validator width="185px"> -->
        <update-img>Profile Picture:</update-img>
      <!-- </Validator> -->
    </div>
    <div class="right">
      <div class="nameInput">
        <div class="nameItem">
          <Validator type="text" :value="form.firstName" :show="chickShow"  @isCheckOkValue="firstNameCheck">
            <input-text placeholder="First Name" v-model="form.firstName">Name:</input-text>
          </Validator>
        </div>
        <div class="nameItem">
          <Validator type="text" :value="form.midName" :show="chickShow" @isCheckOkValue="midNameCheck">
            <input-text placeholder="Mid Name" v-model="form.midName">-</input-text>
          </Validator>
        </div>
        <div class="nameItem">
          <Validator type="text" :value="form.lastName" :show="chickShow" @isCheckOkValue="lastNameCheck">
            <input-text placeholder="Last Name" v-model="form.lastName">-</input-text>
          </Validator>
        </div>
      </div>

      <div class="birthDateBox">
        <div class="titleText"><h2>Date of Birth:</h2></div>
        <div class="birthDateItem">
          <Validator type="drowDownMeun" defaultName="" :titleName="form.dateBirth" :show="chickShow" @isCheckOkValue="dateBirthCheck">
            <el-date-picker
              v-model="form.dateBirth"
              type="date"
              placeholder="Choose Date"
              value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </Validator>
        </div>
        
      </div>

      <div class="nameInput">
        <div class="genderBox">
          <div class="titleText"><h2>Gender:</h2></div>
          <Validator type="drowDownMeun" defaultName="Select" :titleName="form.sexTitle" :show="chickShow" @isCheckOkValue="sexTitleCheck">
            <select-menu :title="form.sexTitle" :width='selectWidth'>
              <option-menu v-for="item in sexList" :key="item.id" @click="selectSexItem(item)">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>
        <div class="genderBox">
          <div class="titleText"><h2>Marital:</h2></div>
          <Validator type="drowDownMeun" defaultName="Select" :titleName="form.maritalTitle" :show="chickShow" @isCheckOkValue="maritalTitleCheck">
            <select-menu :title="form.maritalTitle" :width='selectWidth'>
              <option-menu v-for="item in maritalList" :key="item.id" @click="selectMaritalItem(item)">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>  
      </div>

      <div class="contactBox">
        <div class="titleText"><h2>Contact method:</h2></div>  
        <div class="nameInput">
          <div class="selectItem">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.phoneTitle" :show="chickShow" @isCheckOkValue="phoneTitleCheck">
              <select-menu :title="form.phoneTitle" :width='selectWidth'>
                <option-menu v-for="item in phoneList" :key="item.id" @click="selectphone(item)">{{item.name}}</option-menu>
              </select-menu>
            </Validator>
          </div>
          <div class="phoneItem">
            <Validator type="number" :value="form.PphoneNumberOne" :show="chickShow" @isCheckOkValue="PphoneNumberOneCheck">
              <input-text placeholder="Phone Number" v-model="form.PphoneNumberOne" oninput="value=value.replace(/[^\d.]/g,'')"></input-text>
            </Validator>
          </div>
        </div>
        <div class="nameInput">
          <select-menu :title="form.phoneTitle" :width='selectWidth'>
            <option-menu v-for="item in phoneList" :key="item.id" @click="selectphone(item)">{{item.name}}</option-menu>
          </select-menu>
          <div class="phoneItem">
            <input-text placeholder="Phone Number"></input-text>
          </div>
        </div>
      </div>   
    </div>
    <div class="right">
      <div class="nameItem">
        <Validator :value="form.Address" :show="chickShow" @isCheckOkValue="AddressCheck">
          <input-text placeholder="Address" v-model="form.Address">Contact Address:</input-text>
        </Validator>
      </div>

      <div class="nameInput">
        <div class="nameItem">
          <Validator :value="form.ZIP" :show="chickShow" @isCheckOkValue="ZIPCheck">
            <input-text placeholder="ZIP" v-model="form.ZIP">ZIP:</input-text>
          </Validator>
        </div>
        <div class="nameItem">
          <Validator type="text" :value="form.City" :show="chickShow" @isCheckOkValue="CityCheck">
            <input-text placeholder="City" v-model="form.City">CITY:</input-text>
          </Validator>
        </div>
      </div>

      <div class="nameInput">
        <div class="nameItem">
          <Validator type="text" :value="form.District" :show="chickShow" @isCheckOkValue="DistrictCheck">
            <input-text placeholder="District" v-model="form.District">District:</input-text>
          </Validator>
        </div>
      </div>

      <div class="contactBox">
        <div class="titleText"><h2>Emergency Contact:</h2></div>  
        <div class="nameInput">
          <div class="nameItem">
            <Validator type="text" :value="form.EmergencyFirstName" :show="chickShow" @isCheckOkValue="EmergencyFirstNameCheck">
              <input-text placeholder="First Name" v-model="form.EmergencyFirstName"></input-text>
            </Validator>
          </div>
          <div class="nameItem">
            <Validator type="text" :value="form.EmergencyMidName" :show="chickShow" @isCheckOkValue="EmergencyMidNameCheck">
              <input-text placeholder="Mid Name" v-model="form.EmergencyMidName"></input-text>
            </Validator>
          </div>
          <div class="nameItem">
            <Validator type="text" :value="form.EmergencyLastName" :show="chickShow" @isCheckOkValue="EmergencyLastNameCheck">
              <input-text placeholder="Last Name" v-model="form.EmergencyLastName"></input-text>
            </Validator>
          </div>
        </div>
        <div class="nameInput">
          <select-menu :title="form.phoneTitle" :width='selectWidth'>
            <option-menu v-for="item in phoneList" :key="item.id" @click="selectphone(item)">{{item.name}}</option-menu>
          </select-menu>
          <div class="phoneItem">
            <Validator type="number" :value="form.EmergencyPhoneNumber" :show="chickShow" @isCheckOkValue="EmergencyPhoneNumberCheck">
              <input-text placeholder="Phone Number" v-model="form.EmergencyPhoneNumber" oninput="value=value.replace(/[^\d.]/g,'')"></input-text>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateImg from '@/components/update/UpdateImg.vue'
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu.vue'
import OptionMenu from '@/components/selectMenu/OptionMenu.vue'
import Validator from '@/components/validator/Validator'
export default {
  name:'EmpolyeeFormTwo',
  components: {
    UpdateImg,
    InputText,
    SelectMenu,
    OptionMenu,

    Validator
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      phoneTitle:'Select',
      selectWidth:100,
      sexTitle: 'Select',
      maritalTitle: 'Select',
      dateBirth: '',
      sexList:[
        {
          name:'Man'
        },
        {
          name:'Woman'
        },
      ],
      maritalList:[
        {
          name:'Single'
        },
        {
          name:'Married'
        },
      ],
      phoneList:[
        {
          name:"+1",
        },
        {
          name:"+44",
        },
        {
          name:"+886",
        },
        {
          name:"+44",
        },
      ],
      form:{
        firstName:"",
        midName:"",
        lastName:"",
        dateBirth: "",
        sexTitle: 'Select',
        maritalTitle: 'Select',
        phoneTitle:'Select',
        PphoneNumberOne:"",
        Address:"",
        ZIP:"",
        City:"",
        District:"",
        EmergencyFirstName:"",
        EmergencyMidName:"",
        EmergencyLastName:"",
        EmergencyPhoneNumber:"",
      },
      isCheckAllOkForm:{
        firstNameCheck:false,
        midNameCheck:false,
        lastNameCheck:false,
        dateBirthCheck:false,
        sexTitleCheck:false,
        maritalTitleCheck:false,
        phoneTitleCheck:false,
        PphoneNumberOneCheck:false,
        AddressCheck:false,
        ZIPCheck:false,
        CityCheck:false,
        DistrictCheck:false,
        EmergencyFirstNameCheck:false,
        EmergencyMidNameCheck:false,
        EmergencyLastNameCheck:false,
        EmergencyPhoneNumberCheck:false,
      },
      isFormTwoOk:false,
    }
  },
  methods:{
    selectSexItem(item){
      this.form.sexTitle = item.name
    },
    selectMaritalItem(item){
      this.form.maritalTitle = item.name
    },
    selectphone(item){
      this.form.phoneTitle = item.name
    },

    firstNameCheck(CheckBoolen){
      this.isCheckAllOkForm.firstNameCheck = CheckBoolen
      this.changPage()
    },
    midNameCheck(CheckBoolen){
      this.isCheckAllOkForm.midNameCheck = CheckBoolen
      this.changPage()
    },
    lastNameCheck(CheckBoolen){
      this.isCheckAllOkForm.lastNameCheck = CheckBoolen
      this.changPage()
    },
    dateBirthCheck(CheckBoolen){
      this.isCheckAllOkForm.dateBirthCheck = CheckBoolen
      this.changPage()
    },
    sexTitleCheck(CheckBoolen){
      this.isCheckAllOkForm.sexTitleCheck = CheckBoolen
      this.changPage()
    },
    maritalTitleCheck(CheckBoolen){
      this.isCheckAllOkForm.maritalTitleCheck = CheckBoolen
      this.changPage()
    },
    phoneTitleCheck(CheckBoolen){
      this.isCheckAllOkForm.phoneTitleCheck = CheckBoolen
      this.changPage()
    },
    PphoneNumberOneCheck(CheckBoolen){
      this.isCheckAllOkForm.PphoneNumberOneCheck = CheckBoolen
      this.changPage()
    },
    AddressCheck(CheckBoolen){
      this.isCheckAllOkForm.AddressCheck = CheckBoolen
      this.changPage()
    },
    ZIPCheck(CheckBoolen){
      this.isCheckAllOkForm.ZIPCheck = CheckBoolen
      this.changPage()
    },
    CityCheck(CheckBoolen){
      this.isCheckAllOkForm.CityCheck = CheckBoolen
      this.changPage()
    },
    DistrictCheck(CheckBoolen){
      this.isCheckAllOkForm.DistrictCheck = CheckBoolen
      this.changPage()
    },
    EmergencyFirstNameCheck(CheckBoolen){
      this.isCheckAllOkForm.EmergencyFirstNameCheck = CheckBoolen
      this.changPage()
    },
    EmergencyMidNameCheck(CheckBoolen){
      this.isCheckAllOkForm.EmergencyMidNameCheck = CheckBoolen
      this.changPage()
    },
    EmergencyLastNameCheck(CheckBoolen){
      this.isCheckAllOkForm.EmergencyLastNameCheck = CheckBoolen
      this.changPage()
    },
    EmergencyPhoneNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.EmergencyPhoneNumberCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.firstNameCheck == true 
      && this.isCheckAllOkForm.midNameCheck == true 
      && this.isCheckAllOkForm.lastNameCheck == true 
      && this.isCheckAllOkForm.dateBirthCheck == true 
      && this.isCheckAllOkForm.sexTitleCheck == true 
      && this.isCheckAllOkForm.maritalTitleCheck == true 
      && this.isCheckAllOkForm.phoneTitleCheck == true 
      && this.isCheckAllOkForm.PphoneNumberOneCheck == true 
      && this.isCheckAllOkForm.AddressCheck == true 
      && this.isCheckAllOkForm.ZIPCheck == true 
      && this.isCheckAllOkForm.CityCheck == true 
      && this.isCheckAllOkForm.DistrictCheck == true 
      && this.isCheckAllOkForm.EmergencyFirstNameCheck == true 
      && this.isCheckAllOkForm.EmergencyMidNameCheck == true 
      && this.isCheckAllOkForm.EmergencyLastNameCheck == true 
      && this.isCheckAllOkForm.EmergencyPhoneNumberCheck == true){
        this.isFormTwoOk = true
        this.$emit('isFormTwoOk', this.isFormTwoOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormTwoOk = false
        this.$emit('isFormTwoOk', this.isFormTwoOk)
      }
    },
  },
}
</script>

<style scoped>
#empolyeeformtwo{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.left{
  width: 20%;
  min-width: 240px;
  height: 100%;
}
.right{
  width: 40%;
  margin-right: 60px;
}
.nameInput{
  display: flex;
}
.nameItem{
  min-width: 140px;
  margin-right: 10px;
}
.birthDateBox{
  margin-bottom: 20px;
}
.birthDateItem{
  width: 220px;
}
.titleText{
  margin-bottom: 10px;
}
.genderBox{
  margin-right: 60px;
}
.contactBox{
  margin-top: 20px;
}
.phoneItem{
  margin-left: 5%;
  width: 70%;
}
.selectItem{
  width: 100px;
}
</style>
