<template>
  <div id="updateimg">
    <div class="title"><h2><slot></slot></h2></div>
    <div class="update">
      <svg-icon class="icon" name="image" color="#fff" size="40"></svg-icon>
      <div class="mask"></div>
      <input type="file" class="btn">
      <img :src="imgUrl" class="img">
    </div>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon/SvgIcon.vue'
export default {
  name:'UpdateImg',
  components: {
    SvgIcon
  },
  data() {
    return {

    }
  },
  props:{
    imgUrl:{
      type: String,
      default: "http://finger-trading.oss-cn-hongkong.aliyuncs.com/default/2021/07/09/3661d310-ee67-45f6-a8ec-a6ad797cec00.jpg"
    }
  },
  methods:{},
}
</script>

<style scoped>
.update{
  position: relative;
  width: 180px;
  height: 120px;
  border-radius: 10px;
  border: 2px solid #000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;
  cursor: pointer;
  margin-top: 20px;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 1;
}
.icon{
  position: absolute;
  z-index: 2;
  margin: 50%;
  transform: translate(-50%,-120%);
}
.btn{
  position: absolute;
  border: none;
  outline: none;
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.img{
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
